import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Drawer, IconButton } from "@material-ui/core";
import {
  Menu as MenuIcon,
  ImageOutlined as ImageOutlinedIcon,
  ExitToAppOutlined as ExitToAppOutlinedIcon,
  ReceiptOutlined as ReceiptOutlinedIcon,
  AutorenewOutlined as AutorenewOutlinedRenew,
  PeopleOutlined as PeopleOutlinedIcon,
  SearchOutlined as SearchOutlinedIcon,
  SupervisorAccountOutlined as SupervisorAccountOutlinedIcon,
} from "@material-ui/icons";

import placeholder from "img/profile-placeholder.png";
import { useMobileSliderStyles } from "../styles";

const MobileSlider = ({
  handleLogout,
  handleMyProfile,
  handleBrands,
  user,
  isAdmin,
}) => {
  const classes = useMobileSliderStyles();
  const [open, setOpen] = useState(false);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={toggleDrawer}
        edge="start"
        className={classes.menuIcon}
      >
        <MenuIcon />
      </IconButton>
      <Drawer anchor="left" open={open} onClose={toggleDrawer}>
        <div className={classes.wrapper} onClick={toggleDrawer}>
          {user && (
            <button onClick={handleMyProfile} className={classes.user}>
              <img
                className={classes.avatar}
                src={placeholder}
                alt={user.name}
              />
              <span>{user.name}</span>
              <small>View Profile</small>
            </button>
          )}
          <ul className={classes.menu}>
            <li>
              <button onClick={handleBrands}>
                <SearchOutlinedIcon /> Brands Search
              </button>
            </li>
            <li>
              <Link to="/admin/brands">
                <ImageOutlinedIcon /> Brands
              </Link>
            </li>
            <li>
              <Link to="/admin/orders">
                <ReceiptOutlinedIcon /> Orders
              </Link>
            </li>
            <li>
              <Link to="/admin/renewals">
                <AutorenewOutlinedRenew /> Renewals
              </Link>
            </li>
            <li>
              <Link to="/admin/users">
                <PeopleOutlinedIcon /> Users
              </Link>
            </li>
            {/* {isAdmin && (
              <li>
                <Link to="/adminInspections">
                  <SupervisorAccountOutlinedIcon /> Admin Inspections
                </Link>
              </li>
            )} */}
            <li>
              <button onClick={handleLogout}>
                <ExitToAppOutlinedIcon /> Logout
              </button>
            </li>
          </ul>
          <span style={{ color: "#999", fontSize: "16px", paddingTop: "15px" }}>
            Version {process.env.REACT_APP_VERSION}
          </span>
        </div>
      </Drawer>
    </>
  );
};

MobileSlider.propTypes = {
  handleLogout: PropTypes.func.isRequired,
  handleMyProfile: PropTypes.func.isRequired,
  handleBrands: PropTypes.func.isRequired,
  user: PropTypes.object,
};

export default MobileSlider;
