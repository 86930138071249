import { fromJS } from 'immutable';
import { handleActions, createAction } from 'redux-actions';

const GET_BRAND_REGISTRATIONS_SUCCESS = 'app/REGISTRATION/GET_BRAND_REGISTRATIONS_SUCCESS';

const initialState = fromJS({
  loading: false,
  data: {},
});

const reducer = handleActions({
  [GET_BRAND_REGISTRATIONS_SUCCESS]: (state, { payload: { data } }) => {
    return state.set('data', fromJS(data));
  },
}, initialState);

export const getBrandRegistrationsSuccess = createAction(GET_BRAND_REGISTRATIONS_SUCCESS, (data) => ({ data }));

export const getBrandRegistrations = () => {
  return async function (dispatch, getState, { createAuthClient }) {
    try {
      const { data } = await createAuthClient().get(
        '/admin/brands/registrations',
        { params: { page: 0, size: 1 } },
      );

      dispatch(getBrandRegistrationsSuccess(data));
    } catch (err) {
      console.log(err);
    }
  };
};

export default reducer;
