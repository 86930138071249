/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from "redux-immutable";
import { connectRouter } from "connected-react-router/immutable";
import user from "modules/user";
import brandRegistrations from "modules/brandRegistrations";
import newBrand from "modules/newBrand";
import general from "../modules/general";
/* BEGIN -- Admin Services */
import adminInspectors from "modules/admininspectors";
/* END -- Admin Services */

/**
 * Creates the main reducer
 */
export default function createReducer(injectedReducers = {}, history) {
  return combineReducers({
    router: connectRouter(history),
    general,
    user,
    brandRegistrations,
    /*BEGIN -- Admin Services */
    adminInspectors,
    /* END -- Admin Services */
    newBrand,
    ...injectedReducers,
  });
}
