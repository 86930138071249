import React from 'react';
import { NavLink } from 'react-router-dom';

import { useSubMenuStyles } from '../styles';

const SubMenu = () => {
  const classes = useSubMenuStyles();

  return (
    <ul className={classes.wrapper}>
      <li>
        <NavLink exact to="/admin/registrations" className={classes.link} activeClassName="active">new brands</NavLink>
      </li>
      <li>
        <NavLink exact to="/admin/brands" className={classes.link} activeClassName="active">brands</NavLink>
      </li>
      <li>
        <NavLink exact to="/admin/orders" className={classes.link} activeClassName="active">orders</NavLink>
      </li>
      <li>
        <NavLink exact to="/admin/renewals" className={classes.link} activeClassName="active">renewals</NavLink>
      </li>
      <li>
        <NavLink exact to="/admin/users" className={classes.link} activeClassName="active">users</NavLink>
      </li>
    </ul>
  );
};

export default SubMenu;
