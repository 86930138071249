import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUser, selectIsUserAdmin } from 'selectors/user';

export const AdminRoute = ({ children, ...rest }) => {
  const isAdmin = useSelector(selectIsUserAdmin);
  const user = useSelector(selectUser);

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (isAdmin && user) {
          return children;
        }
        return (
          <Redirect
            to={{
              pathname: '/404',
              state: { from: location }
            }}
          />
        );
      }}
    />
  );
};

export const PrivateRoute = ({ children, ...rest }) => {
  const user = useSelector(selectUser);

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (user) {
          return children;
        }
        return (
          <Redirect
            to={{
              pathname: '/404',
              state: { from: location }
            }}
          />
        );
      }}
    />
  );
};