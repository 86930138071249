import "./App.css";
import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Navigation from "components/Navigation";
import LoadingIndicator from "components/LoadingIndicator";
import LazyComponent from "components/LazyComponent";
import { getUser } from "modules/user";
import { selectUserPending } from "selectors/user";
import { PrivateRoute, AdminRoute } from "./router";

// Admin services --BEGIN
import { getInspectors } from "modules/admininspectors";
import { selectIsUserAdmin } from "selectors/user";
// Admin services --END

const Admin = LazyComponent(import("containers/Admin"));
const AdminDashboard = LazyComponent(import("containers/AdminDashboard"));
const DrawBrand = LazyComponent(import("containers/DrawBrand"));
const NotFound = LazyComponent(import("containers/NotFound"));

// Begin - Admin services
const AdminInspections = LazyComponent(import("containers/adminInspections"));
// END -- Admin services

const App = () => {
  const dispatch = useDispatch();
  const pending = useSelector(selectUserPending);

  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  // BEGIN - Admin services
  useEffect(() => {
    dispatch(getInspectors());
  }, [dispatch]);

  const isUserAdmin = useSelector(selectIsUserAdmin);

  // END -- Admin services
  if (pending) {
    return <LoadingIndicator />;
  }

  return (
    <div className="app">
      <Navigation />
      <div className="app-body">
        <Switch>
          <Redirect exact from="/" to="/admin" />
          <Route
            exact
            path="/feedback"
            component={() => {
              window.location.href =
                "https://www.surveymonkey.com/r/BrandRenewPrototypeFeedback";
              return null;
            }}
          />
          <Route exact path="/404">
            <NotFound />
          </Route>
          <PrivateRoute exact path="/draw">
            <DrawBrand />
          </PrivateRoute>
          {isUserAdmin && (
            <PrivateRoute
              exact
              path={"/adminInspections"}
              component={AdminInspections}
            />
          )}
          <AdminRoute path="/admin/dashboard">
            <AdminDashboard />
          </AdminRoute>
          <AdminRoute path="/admin">
            <Admin />
          </AdminRoute>
          <Redirect to="/404" />
        </Switch>
      </div>
    </div>
  );
};

export default App;
