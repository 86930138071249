const isProdBuild = process.env.NODE_ENV === 'production';
const host = window.location.hostname;
const notLocalhost = host !== 'localhost';

const indexOfDash = host.indexOf('-')
const isProdHost = indexOfDash < 0;
const hostWithoutDashPrefix = host.substring(indexOfDash + 1);
const baseProdDomain = host.substring(host.indexOf('.') + 1);

export const API_BASE_URL = isProdBuild ?
  (isProdHost ?
    `https://api.${baseProdDomain}`
    : (notLocalhost ?
      `https://api-${hostWithoutDashPrefix}`
      : process.env.REACT_APP_API_HOST))
  : process.env.REACT_APP_API_HOST;

export const BRANDS_PORTAL_URL = isProdBuild ?
  (isProdHost ?
    `https://${baseProdDomain}`
    : (notLocalhost ?
      `https://${hostWithoutDashPrefix}`
      : process.env.REACT_APP_BRANDS_PORTAL_HOST))
  : process.env.REACT_APP_BRANDS_PORTAL_HOST;

export const BRANDS_PORTAL = {
  'LOGOUT': BRANDS_PORTAL_URL + '/logout',
  'SEARCH': BRANDS_PORTAL_URL + '/',
  'PROFILE': BRANDS_PORTAL_URL + '/profile',
  url: (relativeUrl) => BRANDS_PORTAL_URL + relativeUrl,
};

const BRAND_TYPE = {
  IRON_CATTLE_HORSE: "Cattle/Horse",
  TATTOO_HORSE: "Horse Tattoo",
  PAINT_SHEEP: "Sheep Paint",
  EARMARK_CATTLE: "Cattle Earmark",
  EARMARK_SHEEP: "Sheep Earmark",
};

export const BRAND_POSITIONS = {
  LS: { description: 'Left Shoulder', type: BRAND_TYPE.IRON_CATTLE_HORSE },
  RS: { description: 'Right Shoulder', type: BRAND_TYPE.IRON_CATTLE_HORSE },
  LR: { description: 'Left Rib', type: BRAND_TYPE.IRON_CATTLE_HORSE },
  RR: { description: 'Right Rib', type: BRAND_TYPE.IRON_CATTLE_HORSE },
  LH: { description: 'Left Hip', type: BRAND_TYPE.IRON_CATTLE_HORSE },
  RH: { description: 'Right Hip', type: BRAND_TYPE.IRON_CATTLE_HORSE },
  // @deprecated Jaw brands are grandfathered but not available otherwise
  LJ: { description: 'Left Jaw', type: BRAND_TYPE.IRON_CATTLE_HORSE, grandFathered: true },
  // @deprecated Jaw brands are grandfathered but not available otherwise
  RJ: { description: 'Right Jaw', type: BRAND_TYPE.IRON_CATTLE_HORSE, grandFathered: true },
  // @deprecated Neck brands are grandfathered but not available otherwise
  LN: { description: 'Left Neck', type: BRAND_TYPE.IRON_CATTLE_HORSE, grandFathered: true },
  // @deprecated Neck brands are grandfathered but not available otherwise
  RN: { description: 'Right Neck', type: BRAND_TYPE.IRON_CATTLE_HORSE, grandFathered: true },
  Li: { description: 'Lip', type: BRAND_TYPE.TATTOO_HORSE, grandFathered: true },

  // D# represents a Cattle Earmark and District No.
  D1: { description: 'District 1', type: BRAND_TYPE.EARMARK_CATTLE },
  D2: { description: 'District 2', type: BRAND_TYPE.EARMARK_CATTLE },
  D3: { description: 'District 3', type: BRAND_TYPE.EARMARK_CATTLE },
  D4: { description: 'District 4', type: BRAND_TYPE.EARMARK_CATTLE },

  Fa: { description: 'Face', type: BRAND_TYPE.PAINT_SHEEP, grandFathered: true },
  CB: { description: 'Center Back', type: BRAND_TYPE.PAINT_SHEEP },
  Wi: { description: 'Withers', type: BRAND_TYPE.PAINT_SHEEP },
  Ru: { description: 'Rump', type: BRAND_TYPE.PAINT_SHEEP, grandFathered: true },
  Hd: { description: 'Head', type: BRAND_TYPE.PAINT_SHEEP, grandFathered: true },
  SE: { description: 'Sheep Earmark', type: BRAND_TYPE.EARMARK_SHEEP },
};

const STARTED = 'STARTED';
const BRAND_APPROVED = 'BRAND_APPROVED';
const OWNERSHIP_APPROVED = 'OWNERSHIP_APPROVED';
const LOGIN_NEEDED = 'LOGIN_NEEDED';
const LOGIN_ATTACHED = 'LOGIN_ATTACHED';
const ADDED_MULTIPLE_BANDS = 'ADDED_MULTIPLE_BANDS';
const CONTINUED_TO_PAY = 'CONTINUED_TO_PAY';
const PRESENT_RECEIPT = 'PRESENT_RECEIPT';
const RECEIPT_PRESENTED = 'RECEIPT_PRESENTED';
const CANCELED_ON_PAY_SCREEN = 'CANCELED_ON_PAY_SCREEN';
const MANUALLY_PROCESSED = 'MANUALLY_PROCESSED';

export const LOG_IN = 'Log in';
export const SIGN_UP = 'Sign up';

export const RENEWAL_STATUSES = {
  STARTED,                // The user used a renewal link to start the renewal process
  BRAND_APPROVED,         // User has pressed the button this is my brand
  OWNERSHIP_APPROVED,     // the User has selected their name as the ownership record
  LOGIN_NEEDED,           // the User has not attached an account to this renewal
  LOGIN_ATTACHED,         // the User has attached an account to this renewal
  ADDED_MULTIPLE_BANDS,   // the User added additional brands at renewal time
  CANCELED_ON_PAY_SCREEN, // the user has clicked the cancel button on govpays side.
  CONTINUED_TO_PAY,       // the user has clicked the pay button and transitioned to govpay
  PRESENT_RECEIPT,        // the govpay payment has been captured and a receipt should be presented
  RECEIPT_PRESENTED,      // the receipt has been presented to the user
  MANUALLY_PROCESSED,     // a user paid by mail or phone and the brand inspector manually processed the payment
};

const MOBILE = 'mobile';
const HOME = 'home';
const OFFICE = 'office';
const FAX = 'fax';
const OTHER = 'other';

export const PHONE_TYPES = {
  MOBILE,
  HOME,
  OFFICE,
  FAX,
  OTHER,
};

const FACEBOOK = 'FACEBOOK';
const GOOGLE = 'GOOGLE';
const LOCAL = 'LOCAL';

export const PROVIDERS = {
  LOCAL,
  FACEBOOK,
  GOOGLE,
  isSocial: provider => provider === FACEBOOK || provider === GOOGLE,
};

export const USA_STATES = {
  'AL': 'Alabama',
  'AK': 'Alaska',
  'AZ': 'Arizona',
  'AR': 'Arkansas',
  'CA': 'California',
  'CO': 'Colorado',
  'CT': 'Connecticut',
  'DE': 'Delaware',
  'DC': 'District Of Columbia',
  'FL': 'Florida',
  'GA': 'Georgia',
  'HI': 'Hawaii',
  'ID': 'Idaho',
  'IL': 'Illinois',
  'IN': 'Indiana',
  'IA': 'Iowa',
  'KS': 'Kansas',
  'KY': 'Kentucky',
  'LA': 'Louisiana',
  'ME': 'Maine',
  'MD': 'Maryland',
  'MA': 'Massachusetts',
  'MI': 'Michigan',
  'MN': 'Minnesota',
  'MS': 'Mississippi',
  'MO': 'Missouri',
  'MT': 'Montana',
  'NE': 'Nebraska',
  'NV': 'Nevada',
  'NH': 'New Hampshire',
  'NJ': 'New Jersey',
  'NM': 'New Mexico',
  'NY': 'New York',
  'NC': 'North Carolina',
  'ND': 'North Dakota',
  'OH': 'Ohio',
  'OK': 'Oklahoma',
  'OR': 'Oregon',
  'PA': 'Pennsylvania',
  'PR': 'Puerto Rico',
  'RI': 'Rhode Island',
  'SC': 'South Carolina',
  'SD': 'South Dakota',
  'TN': 'Tennessee',
  'TX': 'Texas',
  'UT': 'Utah',
  'VT': 'Vermont',
  'VI': 'Virgin Islands',
  'VA': 'Virginia',
  'WA': 'Washington',
  'WV': 'West Virginia',
  'WI': 'Wisconsin',
  'WY': 'Wyoming'
};

export const ORDER_STATUSES = {
  'CREATED': 'In Cart',  // just created
  'CANCELED': 'Canceled', // canceled by the user
  'CAPTURED': 'Paid Pending Review', // capture authorized through GovPay
  'REVERSED': 'Order Failed', // capture reversed through GovPay
  'HIDDEN': 'Hidden',   // capture reversed through GovPay
  'APPROVED': 'Approved', // the order has been approved by staff
  'REFUNDED': 'Refunded', // the order was refunded by staff
  'MANUALLY_PROCESSED': 'Manually Processed',  // a user paid by mail or phone and the brand inspector manually processed the payment
};

export const PRODUCTS = {
  'BRAND_RENEWAL': 'Renewal',
  'NEW_BRAND': 'Registration',
  'BRAND_TRANSFER': 'Transfer',
  'BRAND_RENEWAL_CHEAP': 'Renewal',
};

export const COUNTIES = [
  'Beaver',
  'Box Elder',
  'Cache',
  'Carbon',
  'Daggett',
  'Davis',
  'Duchesne',
  'Emery',
  'Garfield',
  'Grand',
  'Iron',
  'Juab',
  'Kane',
  'Millard',
  'Morgan',
  'Piute',
  'Rich',
  'Salt Lake',
  'San Juan',
  'Sanpete',
  'Sevier',
  'Summit',
  'Tooele',
  'Uintah',
  'Utah',
  'Wasatch',
  'Washington',
  'Wayne',
  'Weber',
  "Out-of-State"
];
