import { fromJS } from "immutable";
import { handleActions, createAction } from "redux-actions";
import { getAccessToken } from "util/APIUtils";
import { BRANDS_PORTAL_URL } from "common/constants";

const GET_USER_SUCCESS = "app/USER/GET_USER_SUCCESS";
const SET_LOADING_INDICATOR = "app/USER/SET_LOADING_INDICATOR";

const initialState = fromJS({
  pending: true,
  user: null,
  isAdmin: false,
  isAdminEdit: false,
});

const reducer = handleActions(
  {
    [GET_USER_SUCCESS]: (state, { payload: { user } }) => {
      return state
        .set("user", fromJS(user))
        .set("isAdmin", !!user.roles.find((role) => role.role === "Admin"))
        .set(
          "isAdminEdit",
          !!user.roles.find((role) => role.role === "Admin Edit")
        )
        .set("pending", false);
    },
    [SET_LOADING_INDICATOR]: (state, { payload: { loading } }) => {
      return state.set("pending", loading);
    },
  },
  initialState
);

const getUserSuccess = createAction(GET_USER_SUCCESS, (user) => ({ user }));
export const setLoadingIndicator = createAction(
  SET_LOADING_INDICATOR,
  (loading) => ({ loading })
);

export const getUser = () => {
  return async function (dispatch, getState, { createAuthClient }) {
    if (getAccessToken()) {
      try {
        const response = await createAuthClient().get("/user/me");

        dispatch(getUserSuccess(response.data));
      } catch (err) {
        window.location.href = BRANDS_PORTAL_URL;
      }
    } else {
      window.location.href = BRANDS_PORTAL_URL;
    }
  };
};

export default reducer;
