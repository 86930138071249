import { fromJS } from 'immutable';
import { handleActions, createAction } from 'redux-actions';

const UPDATE_APP_MESSAGE = 'app/MESSAGE/UPDATE';

const initialState = fromJS({
  message: null,
});

const reducer = handleActions({
  [UPDATE_APP_MESSAGE]: (state, { payload: { message } }) => {
    return state.set('message', message);
  },
}, initialState);

export const updateAppMessage = createAction(UPDATE_APP_MESSAGE, (message) => ({ message }));

export default reducer;
