import "react-app-polyfill/stable";
import "react-app-polyfill/ie11";
import "@acclaim/udaf-kit/dist/index.css";
import React from "react";
import ReactDOM from "react-dom";
import { Switch, Route } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";
import { ConnectedRouter } from "connected-react-router/immutable";
import { ThemeProvider, CssBaseline } from "@material-ui/core";
import { UdafContext } from "@acclaim/udaf-kit";

import { unregister } from "registerServiceWorker";
import App from "containers/App";
import Auth from "containers/Auth";
import configureStore from "lib/configureStore";
import theme from "lib/theme";
import { API_BASE_URL } from "common/constants";

import "./index.css";

const history = createBrowserHistory();
const store = configureStore(history);

const OPTIONS = { API_BASE_URL };

unregister();
ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ThemeProvider theme={theme}>
        <UdafContext.Provider value={OPTIONS}>
          <CssBaseline />
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            autoHideDuration={3000}
            ContentProps={{
              "aria-describedby": "message-id",
            }}
          >
            <Switch>
              <Route exact path="/login">
                <Auth />
              </Route>
              <Route path="/">
                <App />
              </Route>
            </Switch>
          </SnackbarProvider>
        </UdafContext.Provider>
      </ThemeProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);
