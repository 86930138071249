import { createSelector } from "reselect";

const selectUserDomain = (state) => state.get("user");

export const selectUser = createSelector(selectUserDomain, (state) => {
  if (state.get("user")) {
    return state.get("user").toJS();
  }

  return null;
});

export const selectIsUserAdmin = createSelector(selectUserDomain, (state) =>
  state.get("isAdmin")
);

// BEGIN -- Admin Services
export const selectIsUserAdminEdit = createSelector(selectUserDomain, (state) =>
  state.get("isAdminEdit")
);
// END -- Admin Services

export const selectUserPending = createSelector(selectUserDomain, (state) =>
  state.get("pending")
);
