import { createMuiTheme } from "@material-ui/core/styles";

export default createMuiTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: '#C0C64B',
          fontFamily: ['Avenir Book', 'Helvetica', 'Arial', 'sans-serif'].join(', '),
        },
        '.s-alert-box': {
          minWidth: '250px',
        },
        '.s-alert-close::before, .s-alert-close::after': {
          width: '2px',
        },
      },
    },
  },
});
