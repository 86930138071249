import React from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';

import { setAccessToken } from 'util/APIUtils';
import { getUser } from 'modules/user';

export default function Auth() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { location: { search } } = history;

  setAccessToken(queryString.parse(search).id);

  dispatch(getUser());

  return <Redirect to='/admin' />;
}
