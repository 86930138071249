import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import ReactResizeDetector from "react-resize-detector";

import udafLogo from "img/UDAF_logo.png";
import { selectUser, selectIsUserAdmin } from "selectors/user";
import { BRANDS_PORTAL_URL } from "common/constants";
import NavItems from "./components/NavItems";
import SubMenu from "./components/SubMenu";
import MobileSlider from "./components/MobileSlider";
import { useNavigationStyles } from "./styles";

const SHOW_SUB_NAV_FOR = ["/admin"];

const Navigation = ({ history }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [showSubNav, setShowSubNav] = useState(false);
  const classes = useNavigationStyles();
  const user = useSelector(selectUser);
  // BEGIN -- Admin Services
  const isAdmin = useSelector(selectIsUserAdmin);
  // END -- Admin Services
  const handleResize = (width) => {
    if (width <= 960) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  const handleLogout = async () => {
    window.location.href = `${BRANDS_PORTAL_URL}/logout`;
  };

  const handleMyProfile = async () => {
    window.location.href = `${BRANDS_PORTAL_URL}/profile`;
  };

  const handleBrands = async () => {
    window.location.href = `${BRANDS_PORTAL_URL}/`;
  };

  useEffect(() => {
    const show =
      SHOW_SUB_NAV_FOR.filter((key) => {
        return history.location.pathname.startsWith(key);
      }).length > 0;

    setShowSubNav(show);
  }, [history.location.pathname]);

  return (
    <>
      <ReactResizeDetector handleWidth onResize={handleResize} />
      <header className={classes.header}>
        <nav className={classes.nav}>
          <div>
            {isMobile && (
              <MobileSlider
                handleLogout={handleLogout}
                handleMyProfile={handleMyProfile}
                handleBrands={handleBrands}
                user={user}
                isAdmin={isAdmin}
              />
            )}
            <Link to="/" className={classes.brand}>
              <img
                src={udafLogo}
                alt="Utah Department of Agriculture and Food"
              />
              <span>Utah Brands</span>
              <span style={{ color: "#aaa" }}>&nbsp;Admin</span>
            </Link>
          </div>

          <NavItems
            isMobile={isMobile}
            handleLogout={handleLogout}
            handleMyProfile={handleMyProfile}
            handleBrands={handleBrands}
            user={user}
            isAdmin={isAdmin}
          />
        </nav>
        {showSubNav && !isMobile ? (
          <SubMenu />
        ) : (
          <div className={classes.emptySubMenu} />
        )}
      </header>
    </>
  );
};

export default withRouter(Navigation);
