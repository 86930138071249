import { API_BASE_URL, BRANDS_PORTAL } from "common/constants";

export const ACCESS_TOKEN = "accessToken";
const CURRENT_USER_SESSION = "currentUser";

export const _empty = (array) => array && array.length === 0;

export function getAccessToken() {
  return localStorage.getItem(ACCESS_TOKEN);
}

export function setAccessToken(token) {
  localStorage.setItem(ACCESS_TOKEN, token);
}

const getOptionsWithAccessTokenFor = (contentType, options) => {
  const headers = new Headers({ "Content-Type": contentType });

  const accessToken = getAccessToken();
  if (accessToken) {
    headers.append("Authorization", `Bearer ${accessToken}`);
  }

  const defaults = { headers: headers };
  return Object.assign({}, defaults, options);
};

export const request = (options) => {
  options = getOptionsWithAccessTokenFor("application/json", options);
  return fetch(options.url, options).then((response) =>
    response.json().then((json) => {
      if (!response.ok) {
        return Promise.reject(json);
      }
      return json;
    })
  );
};

export function getCurrentUser() {
  if (!getAccessToken()) {
    return Promise.reject("No access token set.");
  }

  const userFromSession = sessionStorage.getItem(CURRENT_USER_SESSION);
  if (userFromSession) {
    return Promise.resolve(JSON.parse(userFromSession));
  }
  return request({
    url: API_BASE_URL + "/user/me",
    method: "GET",
  }).then((returnedUser) => {
    sessionStorage.setItem(CURRENT_USER_SESSION, JSON.stringify(returnedUser));
    return returnedUser;
  });
}

export function saveBrand(brand) {
  // console.log("preparing to post", brand);
  if (brand.id) {
    return request({
      url: `${API_BASE_URL}/admin/brands/${brand.id}`,
      method: "PUT",
      body: JSON.stringify(brand),
    });
  } else {
    return request({
      url: `${API_BASE_URL}/admin/brands`,
      method: "POST",
      body: JSON.stringify(brand),
    });
  }
}

export function getImagesBy(query, passedPage, passedSize) {
  const page = passedPage ? passedPage : 0;
  const size = passedSize ? passedSize : 100;
  return request({
    url: `${API_BASE_URL}/images/?query=${query}&page=${page}&size=${size}`,
    method: "GET",
  });
}

export function getAvailableRoles() {
  return request({
    url: `${API_BASE_URL}/admin/availableRoles`,
    method: "GET",
  });
}

export function getAppRenewalUrl(pinLink) {
  return BRANDS_PORTAL.url(`/${pinLink.brandHash}/${pinLink.pinHash}`);
}

export function addRole(userId, role) {
  return request({
    url: API_BASE_URL + `/admin/users/${userId}/roles`,
    method: "POST",
    body: JSON.stringify(role),
  });
}

export function deleteRole(userId, roleId) {
  return request({
    url: API_BASE_URL + `/admin/users/${userId}/roles/${roleId}`,
    method: "DELETE",
  });
}

export function hasAccount(user) {
  return user && user.id;
}

export function isAdmin(user) {
  return user && user.roles.find((role) => role.role === "Admin");
}

export function toggleRoleDiscontinue(userId, roleId) {
  return request({
    url: API_BASE_URL + `/admin/users/${userId}/roles/${roleId}`,
    method: "PUT",
  });
}

export function getUsersByCriteria(userCriteria, passedPage, passedSize) {
  const page = passedPage ? passedPage : 0;
  const size = passedSize ? passedSize : 10;
  const query = encodeURIComponent(userCriteria);
  return request({
    url: `${API_BASE_URL}/admin/users/search/?query=${query}&page=${page}&size=${size}`,
    method: "GET",
  });
}

export function getOrderBarCodes(orderId) {
  return request({
    url: `${API_BASE_URL}/admin/orders/${orderId}/barcodes`,
    method: "GET",
  });
}

export function getRenewals(query, passedPage, passedSize) {
  const page = passedPage ? passedPage : 0;
  const size = passedSize ? passedSize : 20;
  let url = `${API_BASE_URL}/admin/renewals?page=${page}&size=${size}`;
  if (query) {
    url += "&query=" + encodeURIComponent(query);
  }
  return request({
    url: url,
    method: "GET",
  });
}

export function getOrdersByStatus(status, passedPage, passedSize) {
  const page = passedPage ? passedPage : 0;
  const size = passedSize ? passedSize : 20;
  const query = encodeURIComponent(status);
  return request({
    url: `${API_BASE_URL}/admin/orders?status=${query}&page=${page}&size=${size}`,
    method: "GET",
  });
}

export function getOrdersByQuery(query, passedPage, passedSize) {
  const page = passedPage ? passedPage : 0;
  const size = passedSize ? passedSize : 20;
  return request({
    url: `${API_BASE_URL}/admin/orders?query=${encodeURIComponent(
      query
    )}&page=${page}&size=${size}`,
    method: "GET",
  });
}

export function updateOrders(orders, newStatus) {
  return request({
    url: API_BASE_URL + `/admin/orders`,
    method: "PUT",
    body: JSON.stringify({ orders, newStatus }),
  });
}

export function updateOrder(order) {
  return request({
    url: API_BASE_URL + `/admin/orders/${order.id}`,
    method: "PUT",
    body: JSON.stringify(order),
  });
}

export function removeOrder(orderId) {
  return request({
    url: API_BASE_URL + `/admin/orders/${orderId}`,
    method: "DELETE",
  });
}

export function linkBrand(ownerId, pin) {
  return request({
    url: API_BASE_URL + `/owners/${ownerId}/link`,
    method: "PUT",
    body: pin,
  });
}

export function updateOwner(owner) {
  return request({
    url: API_BASE_URL + `/owners/${owner.id}`,
    method: "PUT",
    body: JSON.stringify(owner),
  });
}

export function renew(brandId) {
  return request({
    url: API_BASE_URL + `/admin/brands/${brandId}/renew`,
    method: "POST",
  });
}

export function replaceAt(oldArray, newEntry) {
  const newArray = oldArray.slice(0);
  const index = newArray.findIndex((oldEntry) => oldEntry.id === newEntry.id);
  newArray[index] = newEntry;
  return newArray;
}
