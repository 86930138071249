import { makeStyles } from '@material-ui/core';

export const useNavigationStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: '#FAFAFB',
    position: 'relative',
    zIndex: '1199',
    [theme.breakpoints.down('sm')]: {
      borderBottom: '2px solid #27783F',
    },
  },
  nav: {
    maxWidth: '1150px',
    padding: '10px 25px',
    margin: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > div:first-child': {
      display: 'flex',
      alignItems: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '10px 15px',
    },
  },
  brand: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '24px',
    color: '#27783F',
    fontWeight: '600',
    marginRight: '15px',
    '&:hover, &:focus': {
      color: '#27783F',
    },
    '& img': {
      width: '38px',
      marginRight: '5px',
      transform: 'translate(0, -2px)',
      [theme.breakpoints.down('sm')]: {
        width: '30px',
      },
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '22px',
    },
  },
  emptySubMenu: {
    borderBottom: '2px solid #27783F',
    maxWidth: '1100px',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      border: 'none',
    },
  },
}));

export const useNavItemsStyles = makeStyles(() => ({
  wrapper: {
    margin: '0',
    padding: '0',
    listStyleType: 'none',
    display: 'flex',
    '& li': {
      marginLeft: '20px',
      height: '30px',
      position: 'relative',
    },
  },
  link: {
    textTransform: 'uppercase',
    fontSize: '13px',
    letterSpacing: '1px',
    display: 'inline-flex',
    alignItems: 'center',
    fontWeight: '600',
    transition: 'all 0.15s',
    color: '#27783f',
    outline: 'none',
    height: '30px',
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    '&:hover, &:focus, &.active': {
      color: 'rgba(0, 0, 0, 0.7)',
    },
    '& svg': {
      color: 'rgba(0, 0, 0, 0.7)',
    }
  },
  avatar: {
    borderRadius: '30px',
    width: '30px',
    height: '30px',
    marginRight: '7px',
    display: 'inline-block',
    border: '2px solid #f1f1f1',
  },
  subMenu: {
    position: 'absolute',
    bottom: '0',
    right: '0',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FAFAFB',
    width: '150px',
    transform: 'translate(0, calc(100% + 5px))',
    borderRadius: '3px',
    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
    opacity: '0',
    visibility: 'hidden',
    transition: 'all 0.15s ease-in-out',
    willChange: 'opacity, visibility',
    '& a, & button': {
      padding: '10px',
      color: 'rgba(0, 0, 0, 0.7)',
      border: 'none',
      background: 'none',
      fontSize: '13px',
      textAlign: 'left',
      cursor: 'pointer',
      outline: 'none',
    },
    '&:hover': {
      opacity: '1',
      visibility: 'visible',
    },
  },
  menu: {
    position: 'relative',
    cursor: 'pointer',
    '&:hover + div': {
      opacity: '1',
      visibility: 'visible',
    },
  },
}));

export const useSubMenuStyles = makeStyles(() => ({
  wrapper: {
    padding: 0,
    margin: 'auto',
    maxWidth: 1100,
    listStyleType: 'none',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#27783F',
    '& li': {
      padding: '15px 15px 0',
    },
  },
  link: {
    textTransform: 'uppercase',
    fontSize: '14px',
    letterSpacing: '1px',
    display: 'inline-flex',
    alignItems: 'center',
    fontWeight: '600',
    transition: 'all 0.15s ease-in-out',
    color: '#ffffff',
    outline: 'none',
    padding: '5px 12px 10px',
    borderRadius: '3px 3px 0 0',
    textAlign: 'center',
    '&:hover': {
      backgroundColor: '#4e9664',
      color: '#ffffff',
      marginBottom: 6,
      paddingBottom: 3,
      borderRadius: '3px',
    },
    '&.active': {
      color: '#27783f',
      backgroundColor: '#FAFAFB',
    },
  },
}));

export const useMobileSliderStyles = makeStyles(() => ({
  wrapper: {
    width: '260px',
    padding: '30px 25px',
    backgroundColor: '#f1f1f1',
    height: '100%',
  },
  menuIcon: {
    color: 'rgba(0, 0, 0, 0.7)',
  },
  header: {
    height: '68px',
    padding: '15px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    borderBottom: '1px solid #f1f1f1',
  },
  user: {
    display: 'flex',
    flexDirection: 'column',
    color: 'rgba(0, 0, 0, 0.87)',
    textDecoration: 'none',
    outline: 'none',
    marginBottom: '30px',
    border: 'none',
    background: 'none',
    '&:hover, &:active': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
    '& img': {
      width: '110px',
      height: '110px',
      borderRadius: '110px',
      marginBottom: '15px',
      boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
    },
    '& span': {
      fontSize: '22px',
      fontWeight: 'bold',
    },
    '& small': {
      fontSize: '14px',
      opacity: '0.8',
    },
  },
  menu: {
    padding: '0',
    margin: '0',
    listStyleType: 'none',
    '& li': {
      marginBottom: '17px',
    },
    '& a, & button': {
      color: 'rgba(0, 0, 0, 0.87)',
      display: 'flex',
      alignItems: 'center',
      fontSize: '16px',
      outline: 'none',
      opacity: '0.8',
      background: 'none',
      padding: '0',
      border: 'none',
      '&:hover, &:active': {
        color: 'rgba(0, 0, 0, 0.87)',
      },
      '& > svg': {
        marginRight: '10px',
      },
    },
  },
}));
