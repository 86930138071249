import { fromJS } from 'immutable';
import { handleActions, createAction } from 'redux-actions';

const GET_NEW_BRAND_SUCCESS = 'app/newBrand/GET_NEW_BRAND_SUCCESS';
const SET_LOADING_INDICATOR = 'app/newBrand/SET_LOADING_INDICATOR';
const SET_ERROR_MESSAGE = 'app/newBrand/SET_ERROR_MESSAGE';
const RESET_STATE = 'app/newBrand/RESET_STATE';

const initialState = fromJS({
  pending: true,
  loading: false,
  data: {},
  error: '',
});

const reducer = handleActions({
  [GET_NEW_BRAND_SUCCESS]: (state, { payload: { brand } }) => {
    return state
      .set('data', fromJS(brand))
      .set('pending', false);
  },
  [SET_LOADING_INDICATOR]: (state, { payload: { loading } }) => {
    return state.set('loading', loading);
  },
  [SET_ERROR_MESSAGE]: (state, { payload: { error } }) => {
    return state.set('error', error);
  },
  [RESET_STATE]: (state) => {
    return initialState;
  },
}, initialState);

export const getNewBrandSuccess = createAction(GET_NEW_BRAND_SUCCESS, (brand) => ({ brand }));
export const setLoadingIndicator = createAction(SET_LOADING_INDICATOR, (loading) => ({ loading }));
export const setErrorMessage = createAction(SET_ERROR_MESSAGE, (error) => ({ error }));
export const resetState = createAction(RESET_STATE);

export const getNewBrand = (registrationId) => {
  return async function (dispatch, getState, { createAuthClient }) {
    const state = getState();
    const userId = state.getIn(['user', 'user', 'id']);

    try {
      const { data } = await createAuthClient().get(`/users/${userId}/registrations/${registrationId}`);

      dispatch(getNewBrandSuccess(data));
    } catch (err) {
      dispatch(setErrorMessage(err?.response?.data?.message || 'Something went wrong. Please try again.'));
    }
  };
};

export const updateRegistration = (formData) => {
  return async function (dispatch, getState, { createAuthClient }) {
    const client = createAuthClient();
    const state = getState();
    const userId = state.getIn(['user', 'user', 'id']);
    const registrationId = state.getIn(['newBrand', 'data', 'id']);

    dispatch(setLoadingIndicator(true));

    try {
      await client.put(
        `/users/${userId}/registrations/${registrationId}`,
        {
          description: formData.description,
          county: formData.county,
        },
      );
      
      await client.put(
        `/users/${userId}/registrations/${registrationId}/addresses`,
        {
          lineOne: formData.lineOne,
          lineTwo: formData.lineTwo || '',
          city: formData.city,
          zipcode: formData.zipcode,
          state: formData.state,
          county: formData.county,
        },
      );

      dispatch(setLoadingIndicator(false));
    } catch (err) {
      dispatch(setErrorMessage(err?.response?.data?.message || 'Something went wrong. Please try again.'));
      dispatch(setLoadingIndicator(false));
    }
  };
};

export const updateOwner = (ownerId, formData) => {
  return async function (dispatch, getState, { createAuthClient }) {
    const client = createAuthClient();
    const state = getState();
    const userId = state.getIn(['user', 'user', 'id']);
    const registrationId = state.getIn(['newBrand', 'data', 'id']);

    dispatch(setLoadingIndicator(true));

    try {
      await client.put(
        `/users/${userId}/registrations/${registrationId}/owners/${ownerId}`,
        formData,
      );

      dispatch(setLoadingIndicator(false));
    } catch (err) {
      dispatch(setErrorMessage(err?.response?.data?.message || 'Something went wrong. Please try again.'));
      dispatch(setLoadingIndicator(false));
    }
  };
};

export default reducer;
