import axios from 'axios';
import { API_BASE_URL } from "../common/constants";

const ACCESS_TOKEN = 'accessToken';

function getToken() {
  return localStorage.getItem(ACCESS_TOKEN);
}

export default axios.create({
  baseURL: API_BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export function createAuthClient() {
  const authApi = axios.create({
    baseURL: API_BASE_URL,
    timeout: 60000,
    headers: {
      'Authorization': `Bearer ${getToken()}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  authApi.interceptors.response.use(null, (err) => {
    const { response } = err;

    // TODO: we can apply unauthenticated interceptor here
    console.error(response);

    return Promise.reject(err);
  });

  return authApi;
}
