import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons";

import placeholder from "img/profile-placeholder.png";
import { useNavItemsStyles } from "../styles";

const NavItems = ({
  isMobile,
  handleLogout,
  handleMyProfile,
  handleBrands,
  user,
  isAdmin,
}) => {
  const classes = useNavItemsStyles();

  if (!isMobile) {
    return (
      <ul className={classes.wrapper}>
        <li>
          <button className={classes.link} onClick={handleBrands}>
            brands search
          </button>
        </li>
        <li>
          <Link className={classes.link} to="/draw">
            draw
          </Link>
        </li>
        <li>
          <span className={`${classes.link} ${classes.menu}`}>
            <img className={classes.avatar} src={placeholder} alt={user.name} />
            <span>{user.name}</span>
            <ExpandMoreIcon />
          </span>
          <div className={classes.subMenu}>
            <Link to="/">Admin</Link>
            {/* {isAdmin && <Link to="/adminInspections">Admin Inspections</Link>} */}
            <button onClick={handleMyProfile}>My Profile</button>
            <button onClick={handleLogout}>Logout</button>
            <span style={{ padding: "5px 10px 10px", color: "#999" }}>
              Version {process.env.REACT_APP_VERSION}
            </span>
          </div>
        </li>
      </ul>
    );
  }

  return null;
};

NavItems.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  user: PropTypes.object,
  handleLogout: PropTypes.func.isRequired,
  handleMyProfile: PropTypes.func.isRequired,
  handleBrands: PropTypes.func.isRequired,
};

export default NavItems;
