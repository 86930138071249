import { createStore, applyMiddleware, compose } from 'redux';
import { fromJS } from 'immutable';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router/immutable';
import createReducer from 'lib/reducers';
import api, { createAuthClient } from 'lib/client';

let store;

export default function configureStore(history) {
  store = createStore(
    createReducer({}, history),
    fromJS({}),
    compose(
      applyMiddleware(
        thunk.withExtraArgument({ api, createAuthClient, history }),
        routerMiddleware(history),
      ),
      window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f,
    ),
  );

  // Reducer registry for code splitting
  store.injectedReducers = {};

  store.injectReducer = (key, asyncReducer) => {
    store.injectedReducers[key] = asyncReducer;
    store.replaceReducer(createReducer(store.injectedReducers, history));
  }

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      store.replaceReducer(createReducer(store.injectedReducers, history));
    });
  }

  return store;
}

export function getStore() {
  return store;
}
